import { amountToDisplayAmount } from "@/utils/price-utils";
import { classNames } from "@/utils/styling-utils";
import type { FormatBaseProps } from "./ui.model";

enum PriceInterval {
  Month = "MONTH",
  Week = "WEEK",
}

export type PriceIntervalType = string | null;

interface PriceProps extends FormatBaseProps {
  amount: number;
  interval?: PriceIntervalType;
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }

  const className = `price__${property}--${value}`;
  return className;
};

export const Price = ({ amount, color, interval, size, style, textDecoration, weight }: PriceProps) => {
  const displayAmount = amountToDisplayAmount(amount);

  const cssColorClass = cssClasses("color", color);
  const cssSizeClass = cssClasses("size", size);
  const cssStyleClass = cssClasses("style", style);
  const cssTextDecorationClass = cssClasses("text-decoration", textDecoration);
  const cssWeightClass = cssClasses("weight", weight);

  // interval er på formatet "antall, oppløsning" (f.eks. "1, MONTH", "2, WEEK", osv.)
  const interval_number = interval ? +interval?.split(", ")[0] : 0;
  const interval_type = interval?.split(", ")[1];

  const priceInterval_type =
    interval_type === PriceInterval.Month
      ? interval_number > 1
        ? "mndr"
        : "mnd"
      : interval_type === PriceInterval.Week
        ? interval_number > 1
          ? "uker"
          : "uke"
        : undefined;

  const priceInterval = priceInterval_type
    ? interval_number > 1
      ? `kr for ${interval_number} ${priceInterval_type}`
      : `kr / ${priceInterval_type}`
    : " kr";

  return (
    <div
      className={classNames(
        "price",
        cssColorClass,
        cssSizeClass,
        cssStyleClass,
        cssTextDecorationClass,
        cssWeightClass,
      )}
    >
      <span className="price__amount">{displayAmount}</span>
      <span className="price__interval">{priceInterval}</span>
    </div>
  );
};
