"use client";
import { usePathname, useSearchParams } from "next/navigation";
import { useState } from "react";

import { shareContentEvent } from "@/analytics/event-logger";
import { type CalendarEvent, createICalLink, getGoogleCalendarLink } from "@/utils/calendar-utils";
import { Logger } from "@/utils/logger";
import { ROOT_URL } from "@/utils/paths-utils";
import { classNames } from "@/utils/styling-utils";
import { CalendarDaysIcon, CopyCheckIcon, CopyIcon, GoogleCalendarIcon, ShareIcon } from "../icons/icons";
import { IconButton } from "./icon-button";
import { IconLinkButton } from "./icon-link-button";

interface ShareButtonsProps {
  buttonType?: "primary";
  calendarEvent?: CalendarEvent;
  shareTitle?: string;

  /**
   * Set shareUrl if you want to share a different URL than the current page URL
   */
  shareUrl?: string;
  showCalendarOnly?: boolean;
}

export const ShareButtons = ({
  buttonType,
  calendarEvent,
  shareTitle,
  shareUrl,
  showCalendarOnly = false,
}: ShareButtonsProps) => {
  const icalLink = calendarEvent ? createICalLink(calendarEvent) : "";
  const googleLink = calendarEvent ? getGoogleCalendarLink(calendarEvent) : "";

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const url = shareUrl ?? `${ROOT_URL}${pathname}${searchParams ? `?${searchParams}` : ""}`;

  const dataToShare = {
    text: `Blir du med${shareTitle ? ` på ${shareTitle}` : ""}?`,
    title: `Bli med${shareTitle ? ` på ${shareTitle}` : ""}`,
    url: url,
  };
  const getShareButton = (showCalendarOnly: boolean) => {
    if (showCalendarOnly) {
      return <></>;
    }

    if (typeof window !== "undefined" && navigator.share && navigator.canShare(dataToShare)) {
      return (
        <IconButton
          ariaLabel="Del aktiviteten med venner"
          buttonType={buttonType}
          onClick={async () => {
            try {
              await navigator.share(dataToShare);
              shareContentEvent(shareTitle, shareUrl);
            } catch (error) {
              Logger.error(error);
            }
          }}
        >
          <ShareIcon />
        </IconButton>
      );
    }

    return (
      <IconButton
        ariaLabel="Kopier link"
        buttonType={buttonType}
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(url).then(() => {
              setIsCopied(true);
            });
            shareContentEvent(shareTitle, shareUrl);
          } catch (error) {
            Logger.error(error);
          }
        }}
      >
        {isCopied ? <CopyCheckIcon /> : <CopyIcon />}
      </IconButton>
    );
  };

  const shareButton = getShareButton(showCalendarOnly);

  return (
    <div className={classNames("share-buttons", buttonType === "primary" && "share-buttons--primary")}>
      {/* Viser 'del'-ikon kun på mobil */}
      {shareButton}

      {icalLink && (
        <IconLinkButton ariaLabel="Legg til i kalender" buttonType={buttonType} href={icalLink} download="event.ics">
          {buttonType === "primary" ? <CalendarDaysIcon color="white" /> : <CalendarDaysIcon />}
        </IconLinkButton>
      )}

      {googleLink && (
        <IconLinkButton
          ariaLabel="Legg til i Google-kalender"
          buttonType={buttonType}
          href={googleLink}
          openUrlInNewTab={true}
        >
          <GoogleCalendarIcon />
        </IconLinkButton>
      )}
    </div>
  );
};
