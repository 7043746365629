import { Button } from "../../base/button";
import { Dialog } from "../../base/dialog";
import { HorizontalContainer } from "../../layout/horizontal-container";
import { HorizontalSpacer } from "../../layout/horizontal-spacer";
import { VerticalContainer } from "../../layout/vertical-container";
import { VerticalSpacer } from "../../layout/vertical-spacer";
import { Text } from "../../ui/text";

interface BookingDialogProps {
  buttonText?: string;
  heading?: string;
  id: string;
  isOpen: boolean;
  onClickButton?: () => void;
  onCloseDialog: () => void;
  text?: string;
}

export const BookingDialog = ({
  buttonText,
  heading,
  id,
  isOpen,
  onClickButton,
  onCloseDialog,
  text,
}: BookingDialogProps) => (
  <Dialog isOpen={isOpen} id={`dialog-${id}`} width="normal" backgroundColor="white">
    <button aria-label="Lukk dialog" className="dialog__close-button" onClick={onCloseDialog} type="button">
      x
    </button>
    <VerticalContainer horizontalPlacement="center">
      {heading && (
        <>
          <Text value={heading} weight="bold" />
          <HorizontalSpacer size="x-small" />
        </>
      )}

      {text && (
        <>
          <Text value={text} />
          <HorizontalSpacer />
        </>
      )}

      {onClickButton && (
        <HorizontalContainer noWrap={true}>
          <Button
            buttonType="neutral"
            onClick={() => {
              onCloseDialog();
              onClickButton();
            }}
          >
            {buttonText}
          </Button>
          <VerticalSpacer />
          <Button buttonType="neutral" onClick={onCloseDialog}>
            Avbryt
          </Button>
        </HorizontalContainer>
      )}
    </VerticalContainer>
  </Dialog>
);
