import type { ReactNode } from "react";

import { HorizontalContainer } from "../layout/horizontal-container";
import { VerticalSpacer } from "../layout/vertical-spacer";
import { Text } from "../ui/text";
import type { FormatBaseProps } from "./ui.model";

interface IconAndTextProps extends FormatBaseProps {
  icon: ReactNode;
  text: string;
  truncate?: boolean;
  verticalPlacement?: "top";
}

export const IconAndText = ({ icon, size, style, text, truncate, verticalPlacement, weight }: IconAndTextProps) => (
  <HorizontalContainer noWrap={true} verticalPlacement={verticalPlacement ?? "center"}>
    {icon}
    <Text size={size ?? "small"} style={style} truncate={truncate} value={text} weight={weight} />
    <VerticalSpacer size="x-small" />
  </HorizontalContainer>
);
