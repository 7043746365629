import { default as LinkNext } from "next/link";
import type { ReactNode } from "react";

import { classNames } from "@/utils/styling-utils";

interface IconLinkButtonProps {
  ariaLabel?: string;
  buttonType?: "primary";
  children: ReactNode;
  download?: string;
  href: string;
  openUrlInNewTab?: boolean;
}

export const IconLinkButton = ({
  ariaLabel,
  buttonType,
  children,
  download,
  href,
  openUrlInNewTab = false,
}: IconLinkButtonProps) => (
  <LinkNext
    aria-label={ariaLabel}
    className={classNames("icon-link-button icon-button", buttonType === "primary" && "icon-button--primary")}
    href={href}
    download={download}
    target={openUrlInNewTab ? "_blank" : undefined}
  >
    {children}
  </LinkNext>
);
