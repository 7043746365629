"use client";

import { sendBookingEvent } from "@/analytics/event-logger";
import { Button } from "@/components/base/button";
import { Heading } from "@/components/base/heading";
import { Link } from "@/components/base/link";
import { CheckIcon, InfoCircleIcon, SmoothieIcon } from "@/components/icons/icons";
import { HorizontalContainer } from "@/components/layout/horizontal-container";
import { HorizontalSpacer } from "@/components/layout/horizontal-spacer";
import { VerticalContainer } from "@/components/layout/vertical-container";
import { VerticalSpacer } from "@/components/layout/vertical-spacer";
import { IconAndText } from "@/components/ui/icon-and-text";
import { Image } from "@/components/ui/image";
import { LinkButton } from "@/components/ui/link-button";
import { ShareButtons } from "@/components/ui/share-buttons";
import { Text } from "@/components/ui/text";
import { UserContext } from "@/contexts/user-context";
import type { CalendarEvent } from "@/utils/calendar-utils";
import { ROOT_URL } from "@/utils/paths-utils";
import { capitalizeWord } from "@/utils/string-utils";
import { classNames } from "@/utils/styling-utils";
import dayjs from "dayjs";
import type React from "react";
import { type ReactNode, useContext, useEffect, useState } from "react";
import { ErrorMessage, bookingTypeToErrorMessageFlavour } from "../../ui/error-message";
import { Price } from "../../ui/price";
import { BookingType } from "../types";
import { BookingDialog } from "./booking-dialog";

require("dayjs/locale/nb");
dayjs.locale("nb");

type Booked = {
  loading: boolean;
  type: "Booked";
  canBeCancelled: boolean;
  cannotBeCancelledReason: string;
  onClickCancel?: () => void;
  calendarEvent: CalendarEvent;
  availableSpots: number | "N/A";
  smoothieEligible?: boolean;
  bookableGroupActivityId: string;
};

type Bookable = {
  loading: boolean;
  type: "Bookable";
  availableSpots: number;
  priceAmount: number;
  canBeCancelled: boolean;
  cannotBeCancelledReason: string;
  onClickBook: () => void;
};

type WaitingList = {
  loading: boolean;
  type: "WaitingList";
  waitingListQueueLength: number;
  onClickWaitinglist: () => void;
};

type OnWaitinglist = {
  loading: boolean;
  type: "OnWaitinglist";
  waitingListPosition: number;
  onWithdrawFromWaitingList: () => void;
  calendarEvent: CalendarEvent;
};

type Unavailable = {
  type: "Unavailable";
  cannotBeBookedReason: string;
};

export enum CannotBeBookedReason {
  TOO_EARLY_TO_BOOK = "TOO_EARLY_TO_BOOK",
  TOO_LATE_TO_BOOK_WAITINGLIST = "TOO_LATE_TO_BOOK_WAITINGLIST",
  TOO_LATE_TO_BOOK = "TOO_LATE_TO_BOOK",
  DELTOK = "DELTOK",
  CANCELLED = "CANCELLED",
  NO_SHOW = "NO_SHOW",
  CANNOT_BOOK_CANCELLED_ACTIVITY = "CANNOT_BOOK_CANCELLED_ACTIVITY",
  TOO_LATE_TO_CANCEL = "TOO_LATE_TO_CANCEL",
}

export type BookingButtonUsecase = Booked | Bookable | WaitingList | OnWaitinglist | Unavailable;

interface BookingButtonAllProps {
  //url til side om aktiviten
  url?: string;
}

interface BookingButtonProps extends BookingButtonAllProps {
  usecase: BookingButtonUsecase;
  center?: string;
  //en errorkode fra backend i stil ERROR_CODE. Denne mappes til konkret feilmelding i error-message
  error?: string;
  name: string;
  startDate: string;
  endDate: string;
  //Brukes for å vise relevante feilmeldinger
  bookingType: BookingType;
  showFullDate: boolean;
  notice?: string;
  imgSrc?: string;
  //Navn på instruktør, tall på bane..etc
  personOrResourceSubtitle?: string;
}

export const BookingButton = ({
  usecase,
  center,
  endDate,
  error: incomingError,
  notice,
  imgSrc = "/images/fallback-desktop.jpg",
  name,
  showFullDate,
  startDate,
  url,
  bookingType,
  personOrResourceSubtitle,
}: BookingButtonProps) => {
  const bookingDate = showFullDate
    ? `${capitalizeWord(dayjs(startDate).format("dddd DD.MM.YYYY kl. HH:mm"))} - ${dayjs(endDate).format("HH:mm")}`
    : dayjs(startDate).format("HH:mm");

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [showNotice, setShowNotice] = useState<boolean>(false);

  useEffect(() => {
    setError(incomingError);
  }, [incomingError]);

  const noticeBar = getNoticeBar(usecase, name, bookingType, showNotice, setShowNotice, url, error, notice);

  return (
    <>
      <div
        className={classNames(
          "booking-button",
          usecase.type === "Booked" && "booking-button--booked",
          (usecase.type === "WaitingList" || usecase.type === "OnWaitinglist") && "booking-button--waiting-list",
          noticeBar && "booking-button--show-message",
        )}
      >
        <HorizontalContainer horizontalPlacement="space" width="full">
          <div className="booking-button__image">
            <BookingButtonLinkWrapper url={url}>
              <Image alt="Bilde av trening" priority={false} src={imgSrc} width={2000} height={800} />
            </BookingButtonLinkWrapper>
          </div>
          <div className="booking-button__main">
            <div className="booking-button__main__left">
              <BookingButtonLinkWrapper url={url}>
                <HorizontalContainer>
                  {/* dates */}
                  <HorizontalContainer noWrap={true}>
                    <Text size="small" value={bookingDate} weight="bold" />
                    <VerticalSpacer />
                  </HorizontalContainer>
                  <Text size="small" value={`${dayjs(endDate).diff(dayjs(startDate), "m")} min`} weight="bold" />
                </HorizontalContainer>

                {/* name and subtitle */}
                <Heading type="h2">{name}</Heading>
                {personOrResourceSubtitle && <Text size="small" value={personOrResourceSubtitle} />}
                <HorizontalSpacer size="x-small" />

                {center && (
                  <HorizontalContainer>
                    {/* center */}
                    <Text size="small" value={center} />
                    <VerticalSpacer size="x-small" />

                    {getSlotText(usecase)}
                  </HorizontalContainer>
                )}
              </BookingButtonLinkWrapper>
            </div>

            <div
              className={classNames(
                "booking-button__main__right",
                usecase.type === "Booked" && "booking-button__main__right--booked",
                (usecase.type === "WaitingList" || usecase.type === "OnWaitinglist") &&
                  "booking-button__main__right--waiting-list",
                usecase.type === "Unavailable" &&
                  (usecase.cannotBeBookedReason === CannotBeBookedReason.TOO_LATE_TO_BOOK ||
                    usecase.cannotBeBookedReason === CannotBeBookedReason.TOO_LATE_TO_BOOK_WAITINGLIST) &&
                  "booking-button__main__right--unavailable-online",
              )}
            >
              {getActionButton(usecase, () => setDialogIsOpen(true), setError, name, center ?? "")}
            </div>
          </div>
        </HorizontalContainer>

        {noticeBar && <div className="booking-button__message">{noticeBar}</div>}
      </div>

      {dialogIsOpen && usecase.type === "Booked" && (
        <BookingDialog
          buttonText="Ja, avbook time"
          heading="Er du sikker på at du vil avbooke timen?"
          id={`${name}-${bookingDate}`}
          isOpen={dialogIsOpen}
          onClickButton={() => {
            usecase.onClickCancel?.();
            sendBookingEvent(name, center ?? "", "cancel");
          }}
          onCloseDialog={() => setDialogIsOpen(false)}
        />
      )}

      {dialogIsOpen && usecase.type === "Bookable" && (
        <BookingDialog
          buttonText="OK, book time"
          heading="For sent å avbooke time"
          id={`${name}-${bookingDate}`}
          isOpen={dialogIsOpen}
          onClickButton={() => {
            usecase.onClickBook();
            sendBookingEvent(name, center ?? "", "book");
          }}
          onCloseDialog={() => setDialogIsOpen(false)}
          text="Hvis du booker denne timen, kan du ikke avbooke den igjen."
        />
      )}

      {dialogIsOpen && usecase.type === "OnWaitinglist" && (
        <BookingDialog
          buttonText="OK, meld meg av"
          heading="Er du sikker på at du vil melde deg av ventelisten?"
          id={`${name}-${bookingDate}`}
          isOpen={dialogIsOpen}
          onClickButton={() => {
            usecase.onWithdrawFromWaitingList();
            sendBookingEvent(name, center ?? "", "cancel_waitinglist");
          }}
          onCloseDialog={() => setDialogIsOpen(false)}
        />
      )}
    </>
  );
};

const getSlotText = (usecase: BookingButtonUsecase) => {
  switch (usecase.type) {
    case "OnWaitinglist": {
      return <Text color="red" size="small" value={`Du er nr ${usecase.waitingListPosition} på venteliste`} />;
    }
    case "WaitingList": {
      return <Text color="red" size="small" value={`${usecase.waitingListQueueLength} på venteliste`} />;
    }
    case "Bookable": {
      const availableText =
        usecase.availableSpots > 15
          ? ">15 ledige"
          : `${usecase.availableSpots} ${usecase.availableSpots > 1 ? "ledige" : "ledig"}`;

      return <Text size="small" value={availableText} />;
    }
    case "Booked": {
      if (usecase.availableSpots === "N/A") {
        return <></>;
      }

      let text = `${usecase.availableSpots} ${usecase.availableSpots > 1 ? "ledige" : "ledig"}`;

      if (usecase.availableSpots > 15) {
        text = ">15 ledige";
      }

      if (usecase.availableSpots === 0) {
        text = "Fullbooket";
      }

      return <Text size="small" value={text} />;
    }
    default: {
      return <></>;
    }
  }
};

const getActionButton = (
  usecase: BookingButtonUsecase,
  setDialogToOpen: () => void,
  setError: (errorCode: string) => void,
  name: string,
  center: string,
) => {
  if (usecase.type === "Booked") {
    const cancelAction = usecase.canBeCancelled
      ? () => {
          setDialogToOpen();
        }
      : () => {
          setError(usecase.cannotBeCancelledReason);
        };

    return (
      <Button onClick={cancelAction} loading={usecase.loading}>
        <VerticalContainer horizontalPlacement="center">
          <CheckIcon size="large" />
          <Text value="Booket" />

          {usecase.canBeCancelled && (
            <>
              <HorizontalSpacer size="x-small" />
              <Text align="center" size="small" underline={true} value="Avbook time" weight="normal" />
            </>
          )}
        </VerticalContainer>
      </Button>
    );
  }

  if (usecase.type === "OnWaitinglist") {
    const withdrawAction = () => {
      setDialogToOpen();
    };

    return (
      <Button onClick={withdrawAction} loading={usecase.loading}>
        <VerticalContainer horizontalPlacement="center">
          <CheckIcon size="large" />
          <Text value="Booket venteliste" />
        </VerticalContainer>
      </Button>
    );
  }

  if (usecase.type === "Unavailable") {
    let cannotBeBooked = "Kan ikke bookes";
    switch (usecase.cannotBeBookedReason) {
      case CannotBeBookedReason.TOO_LATE_TO_BOOK: {
        cannotBeBooked = "Book på senter";
        break;
      }
      case CannotBeBookedReason.CANNOT_BOOK_CANCELLED_ACTIVITY: {
        cannotBeBooked = "Timen er avlyst";
        break;
      }
      case CannotBeBookedReason.TOO_LATE_TO_BOOK_WAITINGLIST: {
        cannotBeBooked = "Book på senter";
        break;
      }
      case CannotBeBookedReason.NO_SHOW: {
        cannotBeBooked = "No show";
        break;
      }
      case CannotBeBookedReason.CANCELLED: {
        cannotBeBooked = "Avbooket";
        break;
      }
      case CannotBeBookedReason.DELTOK: {
        cannotBeBooked = "Deltok";
        break;
      }
      default: {
        break;
      }
    }

    return (
      <Button disabled={true}>
        <Text align="center" value={cannotBeBooked} />
      </Button>
    );
  }

  if (usecase.type === "Bookable") {
    const bookAction = () => {
      if (!usecase.canBeCancelled && usecase.cannotBeCancelledReason === CannotBeBookedReason.TOO_LATE_TO_CANCEL) {
        setDialogToOpen();
      } else {
        sendBookingEvent(name, center, "book");
        usecase.onClickBook();
      }
    };
    return (
      <Button onClick={bookAction} loading={usecase.loading}>
        {usecase.priceAmount > 0 ? (
          <VerticalContainer horizontalPlacement="center">
            <Text align="center" value={"Book time"} />
            <HorizontalSpacer size="xx-small" />
            <Price align="center" amount={usecase.priceAmount} />
          </VerticalContainer>
        ) : (
          <Text align="center" value={"Book time"} />
        )}
      </Button>
    );
  }

  if (usecase.type === "WaitingList") {
    return (
      <Button
        onClick={() => {
          usecase.onClickWaitinglist();
          sendBookingEvent(name, center, "waitinglist");
        }}
        loading={usecase.loading}
      >
        <Text align="center" value={"Book venteliste"} />
      </Button>
    );
  }

  return <></>;
};

const getNoticeBar = (
  usecase: BookingButtonUsecase,
  name: string,
  bookingType: BookingType,
  showNotice: boolean,
  setShowNotice: (showNotice: boolean) => void,
  url?: string,
  error?: string,
  notice?: string,
) => {
  let confirmation: React.ReactElement | undefined;
  // url til booket cageball, golf og squash skal ikke kunne deles eller kopieres, fordi vi ikke har url til spesifikk booking
  const ServicesNotToShare = [BookingType.CAGEBALL, BookingType.GOLFSIMULATOR, BookingType.SQUASH];

  if (usecase.type === "Booked") {
    confirmation = (
      <>
        <SmoothieZone usecase={usecase} />
        <HorizontalContainer horizontalPlacement="space" verticalPlacement="center" width="full">
          <Text size="small" value="Velkommen på trening!" weight="bold" />
          <ShareButtons
            calendarEvent={usecase.calendarEvent}
            shareTitle={name}
            shareUrl={`${ROOT_URL}${url}`}
            showCalendarOnly={ServicesNotToShare.includes(bookingType)}
          />
        </HorizontalContainer>
      </>
    );
  }

  if (usecase.type === "Unavailable") {
    if (usecase.cannotBeBookedReason === CannotBeBookedReason.TOO_EARLY_TO_BOOK) {
      confirmation = <Text size="small" style="italic" value="Denne timen kan dessverre ikke bookes enda" />;
    } else if (usecase.cannotBeBookedReason === CannotBeBookedReason.TOO_LATE_TO_BOOK) {
      confirmation = (
        <Text size="small" style="italic" value="For sent å booke online. Henvend deg til resepsjonen for å booke." />
      );
    }
  }

  if (usecase.type === "OnWaitinglist") {
    confirmation = (
      <HorizontalContainer horizontalPlacement="space" verticalPlacement="center" width="full">
        <Text size="small" value="Du er nå på ventelisten" weight="bold" />
        <ShareButtons
          calendarEvent={usecase.calendarEvent}
          shareTitle={name}
          shareUrl={`${ROOT_URL}${url}`}
          showCalendarOnly={ServicesNotToShare.includes(bookingType)}
        />
      </HorizontalContainer>
    );
  }

  if (error || notice || confirmation) {
    return (
      <>
        {error && (
          <>
            <HorizontalSpacer size="x-small" />
            <ErrorMessage error={error} errorMessageFlavour={bookingTypeToErrorMessageFlavour(bookingType)} />
          </>
        )}

        {notice && (
          <button
            type="button"
            onClick={() => setShowNotice(!showNotice)}
            onKeyDown={() => setShowNotice(!showNotice)}
            tabIndex={0}
          >
            <HorizontalSpacer size="x-small" />
            <IconAndText icon={<InfoCircleIcon />} text={notice} truncate={!showNotice} verticalPlacement="top" />
          </button>
        )}

        {confirmation && (
          <>
            <HorizontalSpacer size="x-small" />
            {confirmation}
          </>
        )}
        <HorizontalSpacer size="xx-small" />
      </>
    );
  }

  return undefined;
};

interface BookingButtonLinkWrapperProps extends BookingButtonAllProps {
  children: ReactNode;
}

const BookingButtonLinkWrapper = ({ children, url }: BookingButtonLinkWrapperProps) => {
  return url ? <Link href={url}>{children}</Link> : <>{children}</>;
};

interface SmoothieZoneProps {
  usecase: BookingButtonUsecase;
}
const SmoothieZone = ({ usecase }: SmoothieZoneProps) => {
  const { myOrders } = useContext(UserContext);
  if (usecase.type === "Booked") {
    const smoothieOrdersMatchingGroupActivityId = myOrders.filter((order) =>
      order.items.some(
        (item) => item.__typename === "ArticleItem" && item.externalMessage === usecase.bookableGroupActivityId,
      ),
    );
    if (!usecase.smoothieEligible) {
      return <></>;
    }
    if (smoothieOrdersMatchingGroupActivityId.length > 0) {
      return (
        <HorizontalContainer>
          <IconAndText icon={<SmoothieIcon size="large" />} text="Husk å hente din smoothie etter timen" />
        </HorizontalContainer>
      );
    }
    return (
      <VerticalContainer>
        <LinkButton href={`/smoothie?id=${usecase.bookableGroupActivityId}`} buttonType="primary-light">
          <HorizontalContainer noWrap={true} verticalPlacement="center">
            <SmoothieIcon />
            <VerticalSpacer size="x-small" />
            <Text value="Lyst på smoothie etter timen?" size="large" underline={false} />
            <VerticalSpacer size="x-small" />
          </HorizontalContainer>
        </LinkButton>
      </VerticalContainer>
    );
  }
  return <></>;
};
