import { Text } from "@/components/ui/text";
import { default as NextImage } from "next/image";
import type { DragEventHandler } from "react";

export interface ImageProps {
  alt: string;
  caption?: string;
  gradient?: boolean;
  onDragStart?: DragEventHandler<HTMLImageElement>;
  priority?: boolean;
  quality?: number;
  src: string;
  placeholder?: string;
  width: number;
  height: number;
}

export const Image = ({
  alt,
  caption,
  onDragStart,
  priority,
  quality = process.env.NEXT_PUBLIC_DEFAULT_IMAGE_QUALITY ? +process.env.NEXT_PUBLIC_DEFAULT_IMAGE_QUALITY : 75,
  src,
  width,
  height,
  placeholder,
}: ImageProps) => {
  const placeholderOrUndefined = src.indexOf("png") !== -1 || src.indexOf("svg") !== -1 ? undefined : placeholder;
  return (
    <>
      <NextImage
        className="image"
        alt={alt}
        onDragStart={onDragStart}
        quality={quality}
        priority={priority}
        src={src}
        width={width}
        height={height}
        placeholder={placeholderOrUndefined ? "blur" : undefined}
        blurDataURL={placeholder ?? undefined}
      />
      {caption && <Text color="gray" size="small" value={caption} />}
    </>
  );
};
